/** @jsxImportSource react */
import { globalStore } from '$/store'
import { useStore } from '@nanostores/react'
import type A11yDialogInstance from 'a11y-dialog'
import { PropsWithChildren, ReactNode, useRef } from 'react'
import ButtonCTA from './ButtonCTA'
import SiteModal from './SiteModal'
import { ReactComponent as MenuIcon } from '$/icons/redesign/menu.svg'
import { ReactComponent as AccountIcon } from '$/icons/redesign/account.svg'

export interface Props {
  buttonGrid?: string // TEMP
  classNames?: {
    button?: string
  } // TEMP
  buttonLabel: string
  headerContent?: ReactNode
  title: string
  titleReplacement?: ReactNode
  footer?: ReactNode
  mobileFullScreen?: boolean
}

export default function HeaderMobileMenuButton(props: PropsWithChildren<Props>) {
  const dialog = useRef<A11yDialogInstance>()
  const { user, subscription } = useStore(globalStore)

  const login = () => {
    window.dispatchEvent(new CustomEvent('ui:login'))
    dialog.current?.hide()
  }

  return (
    <>
      <button
        onClick={() => dialog?.current?.show()}
        aria-label={props.buttonLabel}
        data-grid={props.buttonGrid}
        className={props.classNames?.button}
      >
        <MenuIcon className="nav-icon" />
      </button>

      <SiteModal
        id="mobile-menu"
        dialogRef={(d) => (dialog.current = d)}
        headerContent={
          !user?.id && (
            <button onClick={login} className="action u-flex u-items-center u-gap-2 u-font-medium">
              <AccountIcon className="nav-icon" /> Login
            </button>
          )
        }
        closeButtonLabel="Close menu"
        type="drawer"
        classNames={{
          container: 'site-dialog-container u-z-[90001]',
          overlay: 'site-dialog-overlay',
          dialog: 'site-dialog-content',
          header: 'u-mt-4 u-flex u-items-center u-justify-between u-border-b u-pb-4 u-pl-[15px] u-pr-4',
          title: 'action u-flex u-items-center',
          closeButton: 'u-h-6 u-w-6',
        }}
        title={props.title}
      >
        <div className="u-flex u-flex-1 u-flex-col u-overflow-y-scroll u-px-[15px] u-py-4 u-pb-6 desktop:u-px-4">
          {props.children}
        </div>
        {!subscription?.id && (
          <div className="site-dialog-footer">
            <ButtonCTA />
          </div>
        )}
      </SiteModal>
    </>
  )
}
